import React from 'react';
import {Carousel, Card} from 'react-bootstrap';
import { BsChevronCompactRight, BsChevronCompactLeft} from "react-icons/bs"

import { Divider } from '../Divider/Divider'

import '../../sass/card.scss';


export const SneakerCard = ({images, name, price, sizes, category}) => {

    const carouselItems = images.map( ({image_url, image_bucket}, idx) =>
        <Carousel.Item
            key={idx}>
            <img className="img-fluid w-100" src={image_bucket || image_url} alt={name}/>
        </Carousel.Item>
    );

    const sizesItems = sizes.map( ({size}, index) =>
        <span className="subtitle-third text-uppercase" key={index}>{size}</span>
    );

    return (
        <Card className="mb-3">
            <div className="bd-placeholder-img card-img-top w-100">
                <Carousel
                    slide={false}
                    fade={true}
                    indicators={false}
                    interval={null}
                    nextIcon={<BsChevronCompactRight color={'#000'} size={'2em'}/>}
                    prevIcon={<BsChevronCompactLeft color={'#000'} size={'2em'}/>}>
                    {carouselItems}
                </Carousel>
            </div>
            <Card.Body>
                <div className="card-text">
                    <span className="subtitle-primary text-uppercase">{category}</span>
                    <div className="d-flex justify-content-between">
                        <h3 className="text-uppercase title">{name}</h3>
                        <span className="subtitle-secondary">Precio ${price}</span>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Divider />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <span className="subtitle-third text-uppercase">Tallas</span>
                        </div>
                        <div className="d-flex justify-content-between w-75">
                            {sizesItems}
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}