import React from 'react';
import { Form } from '../Form/Form';

import '../../sass/hero.scss';

export const Hero = ({title, submitFunction}) => {

    return (
        <section className="py-5 text-center container-fluid hero">
            <div className="container">
                <div className="d-flex justify-content-start">
                    <h1 className="fw-ligth">{title}</h1>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Form submitFunction={submitFunction}/>
                    </div>
                </div>
            </div>
        </section>
    );
}