import React, {useState, useEffect} from 'react';

export const Form = ({submitFunction}) => {

    const [numbers, setNumber] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/api/sizes/?categ=Zapatos`, {
                crossDomain:true,
                method: 'GET',
                headers: {
                    'Authorization': `Token ${process.env.REACT_APP_SHOES_API_TOKEN}`
                }
            });
            response = await response.json();
            setNumber(response);
        };
        fetchData();
      }, []);

    const number_options = numbers.map(({size}, idx) => (
        <option key={idx}>{size}</option>
    ));

    return (
        <form onSubmit={submitFunction}>
            <div className="row">
                <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                    <select name="number" className="form-control">
                        <option value={0}>Numero</option>
                        {number_options}
                    </select>
                </div>
                <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                    <button type="submit" className="btn btn-secondary w-100 border-white">Buscar</button>
                </div>
            </div>
        </form>
    );
}