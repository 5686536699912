import { ShoesContainer } from './components/ShoesContainer'
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

function App() {

    return (
      <main>
        <ShoesContainer />
        <WhatsAppWidget phoneNumber='+528110610100' />
      </main>
    );
  }

  export default App;
