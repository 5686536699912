import React from 'react';

// import '../../sass/divider.scss';


export const NotFound = () => {

    return (
        <div className="row">
            <div className="col-12 justify-content-center">
                <h2>No se encontraron resultados</h2>
            </div>
        </div>
    );
}