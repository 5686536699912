import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faHeart, faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'


export const Footer = () => {

    const coffee = <FontAwesomeIcon icon={faCoffee} />
    const heart = <FontAwesomeIcon icon={faHeart} />
    const email = <FontAwesomeIcon icon={faMailBulk} />
    const facebook = <FontAwesomeIcon icon={faFacebook} />


    return (
        <footer className="text-center text-white bg-light">
        <div className="container pt-4">
            <section className="mb-4">
                <a
                    className="btn btn-link btn-floating btn-lg text-dark m-1"
                    href="#!"
                    role="button"
                    data-mdb-ripple-color="dark"
                    >
                        {facebook}
                </a>

                <a
                    className="btn btn-link btn-floating btn-lg text-dark m-1"
                    href="mailto:jlgarza@hotmail.com"
                    role="button"
                    data-mdb-ripple-color="dark"
                    >
                        {email}
                </a>

            </section>
        </div>

        <div className="text-center text-dark p-3 .bg-dark">
            Made with {coffee} and {heart} by <a className="text-dark" href="https://leonardoalonso.github.io/leo-portfolio/">LeoADev</a>
        </div>
        </footer>
    );
}
