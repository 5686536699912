export const filterShoes = (shoesList, filterObject) => {
    const filteredList = shoesList.filter((element) => {
        if (filterObject['number']) {
            const sizes = element.size_ids.map((element) => Number(element.size));
            return sizes.includes(filterObject['number']);
        } else {
            return shoesList;
        }
    })

    return filteredList;
}

export const getShoes = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}/api/product/?categ=Zapatos`, {
        crossDomain:true,
        method: 'GET',
        headers: {
            'Authorization': `Token ${process.env.REACT_APP_SHOES_API_TOKEN}`
        }
    });
    response = await response.json();
    return response;
};
