import React from 'react';

import '../../sass/divider.scss';


export const Divider = () => {

    return (
        <div className="row row-divider">
            <div className="col-9">
                <hr className="divider"/>
            </div>
        </div>
    );
}