import React from 'react';
import { SneakerCard } from '../SneakerCard/SneakerCard';
import { NotFound } from '../NotFound/NotFound'
import {CardColumns} from 'react-bootstrap';


import '../../sass/album.scss';

export const Album = ({shoes}) => {

    const cards = shoes.map((shoe, idx) =>
        <SneakerCard
            name={shoe.name}
            images={shoe.images}
            price={shoe.price}
            sizes={shoe.size_ids}
            category={shoe.subcateg_id.name}
            key={idx}/>
    );

    return (
        <>
        {
            shoes.length > 0 ?
                <div className="container-fluid mt-3">
                    <CardColumns>
                        {cards}
                    </CardColumns>
                </div>
            : <NotFound/>
        }
        </>
    );
}