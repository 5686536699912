import React, {useState, useEffect} from 'react'
import { Album } from './Album/Album';
import { Hero } from './Hero/Hero';

import {filterShoes, getShoes} from '../helpers/filterShoes'
import { Footer } from './Footer/Footer';

export const ShoesContainer = () => {

    const [_shoes, setShoes] = useState([])
    const [filteredShoes, setFilteredShoes] = useState([])

        useEffect(() => {
            const fetchData = async () => {
                const shoes = await getShoes();
                setFilteredShoes(shoes);
                setShoes(shoes);
            };
            fetchData();
        }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const numberField = event.target.number;
        const number = numberField.value !== "0" ? Number(numberField.value) : false;

        const filterObject = {
            'number': number
        }
        if (filterObject['number']) {
            const filtered = filterShoes(_shoes, filterObject);
            setFilteredShoes(filtered);
        } else {
            setFilteredShoes(_shoes);
        }
    }

    return (
        <>
            <Hero title="Sneakers" submitFunction={handleSubmit} />
            <Album shoes={filteredShoes}/>
            <Footer/>
        </>
    );
}
